import * as styles from './CubeWillOutliveMagicPlaymatPage.module.css'

import React from 'react'

import ProductLayout from './ProductLayout'

import CubeWillOutliveMagicPlaymatRender from './images/cube-will-outlive-magic-playmat-render.jpg'
import CubeWillOutliveMagicPlaymatImage from './images/cube-will-outlive-magic-playmat.jpg'
import CubeWillOutliveMagicPlaymatDetail1Image from './images/cube-will-outlive-magic-playmat-detail-1.jpg'
import CubeWillOutliveMagicPlaymatDetail2Image from './images/cube-will-outlive-magic-playmat-detail-2.jpg'
import CubeWillOutliveMagicPlaymatDetail3Image from './images/cube-will-outlive-magic-playmat-detail-3.jpg'
import CubeWillOutliveMagicPlaymatDetail4Image from './images/cube-will-outlive-magic-playmat-detail-4.jpg'
import CallToAction from './CallToAction'

const CubeWillOutliveMagicPlaymatPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <ProductLayout
        imageColumn={
          <>
            <img
              className={styles.image}
              src={CubeWillOutliveMagicPlaymatRender}
              alt="CubeCon 2023 Cube Map Print — Render"
            />
            <img
              className={styles.image}
              src={CubeWillOutliveMagicPlaymatImage}
              alt="CubeCon 2023 Cube Map Print — Full Size"
            />
            <img
              className={styles.image}
              src={CubeWillOutliveMagicPlaymatDetail2Image}
              alt="CubeCon 2023 Cube Map Print — Detail 1"
            />
            <img
              className={styles.image}
              src={CubeWillOutliveMagicPlaymatDetail1Image}
              alt="CubeCon 2023 Cube Map Print — Zoomed In"
            />
            <img
              className={styles.image}
              src={CubeWillOutliveMagicPlaymatDetail3Image}
              alt="CubeCon 2023 Cube Map Print — Detail 2"
            />
            <img
              className={styles.image}
              src={CubeWillOutliveMagicPlaymatDetail4Image}
              alt="CubeCon 2023 Cube Map Print — Detail 2"
            />
          </>
        }
      >
        <div className={styles.info}>
          <h1 className={styles.title}>Cube Will Outlive Magic Playmat</h1>
          <div className={styles.description}>
            Bans got you down? Magic&rsquo;s latest IP crossover cash-grab
            making you question whether the game is even for you any more? Carry
            this playmat as a reminder of Magic&rsquo;s punk rock, DIY soul.
            Cube will outlive Magic.
          </div>
          <div className={styles.description}>
            After a positive community response to the &ldquo;Cube will outlive
            Magic&rdquo; slogan our local playgroup adorned our custom track
            jackets we designed for CubeCon last year, we created this
            illustration, an homage to{' '}
            <a href="https://en.wikipedia.org/wiki/Dance_%28Matisse%29">
              Matisse&rsquo;s iconic <i>Dance</i>
            </a>
            , to celebrate our favorite format&rsquo;s plucky, independent
            spirit.
          </div>
          <div className={styles.description}></div>
          <ul className={styles.details}>
            <li>Illustration by Andy Mangold</li>
            <li>Solid black, premium playmat with stitched edge</li>
            <li>Applied gold foil accent</li>
            <li>Printed and foiled in the United States</li>
            <li>24&#x2033; x 14&#x2033; (~610mm x ~356mm)</li>
            <li>Free shipping anywhere in the world</li>
          </ul>
          <div className={styles.soldOut}>Sold Out</div>
        </div>
      </ProductLayout>
    </div>
  )
}

export default CubeWillOutliveMagicPlaymatPage
